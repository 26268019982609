import { Form } from 'antd'
import { getNoticeClients } from 'api/requests'
import { getFormDateToYYYYMMDD } from 'Components/dates'
import { DEFAULT_FILTER } from 'Components/Table/Constants'
import { BasicFilterModel, OrderOptionState } from 'domain/Orders'
import { useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { historicalNoticeFilterAtom } from 'recoil/atom'
import Filters from './Filters'
import HistoricalList from './List'

interface Props {
  stateOptions: OrderOptionState[]
}
export const Historical = ({ stateOptions }: Props) => {
  const [form] = Form.useForm()
  const [data, setData] = useState<any>({})
  const [status, setStatus] = useState<'loading' | 'error' | 'success'>('loading')
  const [currentPage, setCurrentPage] = useState(1)
  const setNoticeFilter = useSetRecoilState(historicalNoticeFilterAtom)

  const loadData = async (filter: BasicFilterModel) => {
    setStatus('loading')

    setData({ ...data, status: 'loading' })
    const updatedFilter = {
      ...filter,
      fechaFin: getFormDateToYYYYMMDD(filter.fechaFin),
      fechaInicio: getFormDateToYYYYMMDD(filter.fechaInicio),
    }
    const response = await getNoticeClients(updatedFilter)
    setData(response)
    setNoticeFilter({
      fechaFin: updatedFilter.fechaFin,
      fechaInicio: updatedFilter.fechaInicio,
      textoBusqueda: updatedFilter.textoBusqueda,
      grupoId: updatedFilter?.grupoId,
    })
    setStatus('success')
  }
  const handlePagination = async (page: any, pageSize: any) => {
    const filterValues = form.getFieldsValue()
    const updatedFilter: BasicFilterModel = {
      ...filterValues,
      numPagina: page,
      numresultados: pageSize,
    }
    await loadData(updatedFilter)
    form.setFieldsValue(updatedFilter)
    setCurrentPage(page)
  }

  useEffect(() => {
    loadData(DEFAULT_FILTER)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div>
      <Filters stateOptions={stateOptions} form={form} onSearch={loadData} />
      <HistoricalList data={data} status={status} onChangePagination={handlePagination} currentPage={currentPage} />
    </div>
  )
}

export const formatTableColumns = (columns: any[]) => {
  const CELL_COLOR = {
    Red: '#FD5D5D',
    Green: '#ADE792',
    Empty: undefined,
  }
  return columns.map((item) => {
    if (item.dataIndex === 'estado') {
      return {
        ...item,
        width: 200,
        render: (value: string, record: any) => (
          <div style={{ position: 'relative', border: '0.2px solid #D0CBB7' }}>
            <div
              style={{
                backgroundColor: CELL_COLOR[(record.estadoColor as 'Green' | 'Red' | 'Empty') || 'Empty'],
                width: `${record.estadoPorcentajeBarra > 100 ? 100 : record.estadoPorcentajeBarra}%`,
                minHeight: '3em',
                border: '0.2px solid #D0CBB7',
              }}
            ></div>
            <div
              style={{
                textAlign: 'center',
                position: 'absolute',
                alignItems: 'center',
                left: '50%',
                top: '20%',
                transform: 'translateX(-50%)',
                zIndex: 9,
                width: '100%',
                fontWeight: '600',
              }}
            >
              {value}
            </div>
          </div>
        ),
      }
    }

    return item
  })
}
export const retardedToRequest = (value: 'all' | 'isDelayed' | 'notDelayed' | null | undefined) => {
  if (value === null || value === undefined) {
    return null
  }
  const format = { all: null, isDelayed: true, notDelayed: false }
  return format[value]
}

import { SearchOutlined, SendOutlined } from '@ant-design/icons'
import { Button, Col, Form, FormInstance, Input, Popconfirm, Row, Select } from 'antd'
import { LocalDatePicker } from 'Components/LocalDatePicker'
import { DEFAULT_FILTER } from 'Components/Table/Constants'
import { ResponseNoticeListModel } from 'domain/Notice'
import { BasicFilterModel, OrderOptionState } from 'domain/Orders'
import { STYLES } from 'Pages/Orders/OrderList/syles'
import { nameof } from 'ts-simple-nameof'
interface Props {
  form: FormInstance<BasicFilterModel>
  onSearch: (values: any) => void
  sendAll: () => void
  data: ResponseNoticeListModel
  stateOptions: OrderOptionState[]
}
const Filters = ({ form, onSearch, sendAll, data, stateOptions }: Props) => {
  return (
    <Row justify="space-between">
      <Col span={22}>
        <Form
          form={form}
          initialValues={DEFAULT_FILTER}
          name="horizontal_login"
          layout="inline"
          onFinish={(values) => onSearch({ ...values, ...DEFAULT_FILTER })}
          style={{ paddingBottom: '1em', columnGap: '12px' }}
        >
          <Form.Item name={nameof<BasicFilterModel>((item) => item.textoBusqueda)}>
            <Input prefix={<SearchOutlined />} placeholder="Buscar" />
          </Form.Item>
          <Form.Item>y/o</Form.Item>

          <Form.Item name={nameof<BasicFilterModel>((item) => item.fechaInicio)} label="Fecha de Creación">
            <LocalDatePicker placeholder="Fecha inicial" />
          </Form.Item>
          <Form.Item name={nameof<BasicFilterModel>((item) => item.fechaFin)} label="">
            <LocalDatePicker placeholder="Fecha final" />
          </Form.Item>
          <Form.Item label="" name={nameof<BasicFilterModel>((item) => item.grupoId)}>
            <Select style={{ width: '12em' }} placeholder="Seleccionar grupo">
              <Select.Option value={null}>Seleccionar grupo</Select.Option>
              {stateOptions.map((item) => {
                return (
                  <Select.Option value={item.id} key={item.id}>
                    {item.descripcion}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item style={STYLES.search}>
            <Button type="primary" htmlType="submit">
              Buscar
            </Button>
          </Form.Item>
          <Form.Item>
            <Popconfirm
              title={`¿Está seguro que desea marcar todos tus avisos (${data.result.total})?`}
              onConfirm={sendAll}
            >
              <Button icon={<SendOutlined />} type="default">
                Confirmar todos
              </Button>
            </Popconfirm>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

export default Filters

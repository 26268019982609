import { FilterOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Modal, Row } from 'antd'
import { useState } from 'react'
interface Props {
  columns: string[]
  setColumns: (value: any) => void
  options: { title: any; dataIndex: any }[]
}

interface Props {
  columns: string[]
  setColumns: (value: any) => void
  options: { title: any; dataIndex: any }[]
}

export const ButtonHideColumns = ({ columns, setColumns, options }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleCheckboxChange = (e: any) => {
    const { value, checked } = e.target
    if (checked) {
      setColumns([...columns, value])
    } else {
      setColumns(columns.filter((column) => column !== value))
    }
  }
  if (options.length === 0) return null

  return (
    <>
      <Button type="primary" onClick={showModal} icon={<FilterOutlined />}>
        Mostrar Columnas
      </Button>
      <Modal
        okText={'Cerrar'}
        title="Mostrar columnas"
        open={isModalOpen}
        onOk={handleOk}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={handleCancel}
      >
        <Row style={{ paddingTop: '1.5em' }}>
          {options.map((item) => (
            <Col xs={12}>
              <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '0.3em' }} key={item.dataIndex}>
                <Checkbox
                  value={item.dataIndex}
                  checked={columns.includes(item.dataIndex)}
                  onChange={handleCheckboxChange}
                >
                  {item.title}
                </Checkbox>
              </div>
            </Col>
          ))}
        </Row>
      </Modal>
    </>
  )
}

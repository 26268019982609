import { Layout, Row } from 'antd'
import UserMenuOptions from './UserMenuOptions'

const { Header: HeaderLayout } = Layout

function Header() {
  return (
    <HeaderLayout>
      <Row style={{ justifyContent: 'space-between', alignItems: 'center', paddingInline: '14px 20px' }}>
        <div style={{ height: 42, paddingBlock: 5 }}>
          <img src="./logo_europastry_white.png" color="white" style={{ height: '100%' }} />
        </div>

        <div style={{ height: 30, paddingBlock: 5 }}>
          <UserMenuOptions />
        </div>
      </Row>
    </HeaderLayout>
  )
}

export default Header

import { Table } from 'Components/Table'
import { ResponseOrderModel } from 'domain/Orders'
import { DEFAULT_PAGINATION_CONFIG } from '../../../Components/Table/Constants'
import { InlineRow } from './InlineRow'
// const [selectedRowId, setSelectedRowId] = useState('')

interface Props {
  data: ResponseOrderModel
  onChange: any
  selectedRowId?: any
  setSelectedRowId?: (value: any) => void
  columns: any[]
}
const OrderTable = ({ data, onChange, selectedRowId, setSelectedRowId, columns }: Props) => {
  const tableProps =
    typeof setSelectedRowId === 'undefined'
      ? undefined
      : {
          rowClassName: (record: any, index: number) => {
            if (record.identificador === selectedRowId) {
              return 'selected-row'
            }
            if (index % 2 === 0) {
              return index.toString()
            }
            return 'row-odd'
          },
          onRow: (record: any, rowIndex: any) => {
            return {
              onClick: (event: any) => {
                setSelectedRowId(record.identificador)
              },
            }
          },
        }

  return (
    <div>
      <Table
        {...tableProps}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => <InlineRow record={record} columns={data.result.columnas} />,
        }}
        size="small"
        dataSource={data.result.datos}
        onChange={onChange}
        loading={data.status === 'loading'}
        rowKey={'identificador'}
        scroll={{ x: '200vw', y: '60vh' }}
        pagination={{
          ...DEFAULT_PAGINATION_CONFIG,
          total: data.result.total,
        }}
      />
    </div>
  )
}

export default OrderTable

import { makePostRequest } from 'api/apiCalls'
import { ENVIRONMENT_CONFIG, MICROSOFT_OPTIONS } from './environmentOptions'

const ENDPOINT = `${ENVIRONMENT_CONFIG.API}/MicrosoftAuth`

export const postAuth = (microsoftIdToken: string): Promise<string> => {
  return makePostRequest(`${ENDPOINT}/auth-microsoft`, { ...MICROSOFT_OPTIONS, microsoftIdToken }, {}).then(
    (requestData) => requestData
  )
}

export const postSilentLogin = (): Promise<string> => {
  return makePostRequest(`${ENDPOINT}/silent-login`, {}, {}).then((requestData) => requestData)
}

import { postSilentLogin } from "./api";

class TokenManager {
  private accessToken: string | null = null;
  private refreshToken: string | null = null;

  // Set the access token and refresh token
  setTokens(accessToken: string, refreshToken: string) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
  }

  // Get the current access token
  getAccessToken(): string | null {
    return this.accessToken;
  }

  // Get the current refresh token
  getRefreshToken(): string | null {
    return this.refreshToken;
  }

  // Check if the user is authenticated (has an access token)
  isAuthenticated(): boolean {
    return !!this.accessToken;
  }

  // Clear tokens on logout
  clearTokens() {
    this.accessToken = null;
    this.refreshToken = null;
  }

  // Implement a method to refresh the access token using the refresh token
  // You would typically make an API request here to exchange the refresh token for a new access token
  async refreshAccessToken(): Promise<void> {
    try {
      // Replace this with your actual API call to refresh the access token
      const newAccessToken = await postSilentLogin();
      this.accessToken = newAccessToken;
    } catch (error) {
      console.error("Error refreshing access token:", error);
      throw error;
    }
  }
}
const tokenManager = new TokenManager();
export default tokenManager;

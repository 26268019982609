import { AlertOutlined, TableOutlined } from '@ant-design/icons'
import { Notice } from 'Pages/Notice'
import { Orders } from 'Pages/Orders'

export const useRoutesHook = () => {
  const routes: { path: string; name: string; Component: React.FunctionComponent; icon: React.ReactNode }[] = [
    { path: '/pedidos', name: 'Pedidos', Component: Orders, icon: <TableOutlined /> },
    { path: '/avisos', name: 'Avisos', Component: Notice, icon: <AlertOutlined /> },
  ]
  return routes
}

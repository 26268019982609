import { Checkbox } from 'antd'
import { getDDMMYYYY } from 'Components/dates'

export const generateTableColumns = (columns: any[]) => {
  return columns.map((item) => {
    if (item.dataIndex.toLowerCase().includes('fecha')) {
      return {
        ...item,
        width: 120,
        render: (value: string) => <div style={{ whiteSpace: 'pre-wrap' }}>{getDDMMYYYY(value)}</div>,
      }
    }
    if (item.dataIndex.toLowerCase() === 'mensaje') {
      return { ...item, textWrap: 'word-break', ellipsis: true, width: 550 }
    }
    if (item.dataIndex.toLowerCase() === 'contenedor') {
      return { ...item, textWrap: 'word-break', ellipsis: true, width: 450 }
    }
    if (item.dataIndex.toLowerCase() === 'facturas') {
      return { ...item, textWrap: 'word-break', ellipsis: true, width: 200 }
    }
    if (item.dataIndex.toLowerCase() === 'clienteFacturacion') {
      return { ...item, textWrap: 'word-break', ellipsis: true, width: 300 }
    }
    return {
      ...item,
      textWrap: 'word-break',
      width: 100,
      render: (value: any) => {
        const isCheckbox = value === true || value === false
        return isCheckbox ? (
          <Checkbox checked={value} disabled style={{ width: '100%', position: 'relative', left: '45%' }} />
        ) : (
          <div style={{ whiteSpace: 'pre-wrap' }}>{value}</div>
        )
      },
    }
  })
}

export const ROW_ID = 'rowId'
export const dataSourceWithId = (items: any[]) => items.map((item, index) => ({ ...item, [ROW_ID]: index }))

import {
  CheckSelectedModel,
  GroupNoticeFilterModel,
  ResponseNoticeListModel,
  ResponseNoticeOptionsModel,
} from 'domain/Notice'
import {
  BasicFilterModel,
  NoticeFilterModel,
  OrderFilterRequestModel,
  ResponseOrderModel,
  ResponseStatesModel,
} from 'domain/Orders'
import { makeGetRequest, makePostRequest, makePutRequest } from './apiCalls'
import { ApiUrls } from './Enums'
import { GetUserInfoModel } from 'domain/User'

export const getOrdersByFilter = async (filter: OrderFilterRequestModel): Promise<ResponseOrderModel> =>
  makePostRequest(`${ApiUrls.EUROPASTRY}/Pedido`, filter).then((response) => response)

export const postExportOrdersByFilter = async (filter: BasicFilterModel): Promise<any> =>
  makePostRequest(`${ApiUrls.EUROPASTRY}/Pedido/exportar`, filter).then((response) => response)

export const getOrderState = async (): Promise<ResponseStatesModel> =>
  makeGetRequest(`${ApiUrls.EUROPASTRY}/Pedido/estados`, {}).then((response) => response)

export const getHistoricalOrdersByFilter = async (filter: OrderFilterRequestModel): Promise<ResponseOrderModel> =>
  makePostRequest(`${ApiUrls.EUROPASTRY}/Pedido/historico`, filter).then((response) => response)

export const getNoticeByFilter = async (filter: BasicFilterModel): Promise<ResponseNoticeListModel> =>
  makePostRequest(`${ApiUrls.EUROPASTRY}/Aviso`, filter).then((response) => response)

export const getCheckedSelected = async (filter: CheckSelectedModel): Promise<ResponseOrderModel> =>
  makePutRequest(`${ApiUrls.EUROPASTRY}/Aviso/MarcarRecibidos`, filter)

export const getNoticeClients = async (filter: BasicFilterModel): Promise<ResponseOrderModel> =>
  makePostRequest(`${ApiUrls.EUROPASTRY}/Aviso/clientes`, filter).then((response) => response)

export const getNoticeOrders = async (filter: NoticeFilterModel): Promise<ResponseOrderModel> =>
  makePostRequest(`${ApiUrls.EUROPASTRY}/Aviso/pedidos`, filter).then((response) => response)

export const getNoticeGroups = async (filter: any): Promise<ResponseOrderModel> =>
  makePostRequest(`${ApiUrls.EUROPASTRY}/Aviso/grupos`, filter).then((response) => response)

export const getNoticesGroupsNotice = async (filter: GroupNoticeFilterModel): Promise<ResponseNoticeListModel> =>
  makePostRequest(`${ApiUrls.EUROPASTRY}/Aviso/avisosgrupos`, filter).then((response) => response)

export const getNoticeGroupsOptions = async (): Promise<ResponseNoticeOptionsModel> =>
  makeGetRequest(`${ApiUrls.EUROPASTRY}/Aviso/grupos`, {}).then((response) => response)

export const getSabana = async (
  id: any
): Promise<any> => // TODO falta definir back
  makeGetRequest(`${ApiUrls.EUROPASTRY}/Pedido/${id}/sabana`, {}).then((response) => response)

// export const deleteRole = (newRole: any) => makeDeleteRequest(`${ApiUrls.USERS}/Role/Delete`, newRole)
export const getDownloadFile = (id: number) => {
  return makeGetRequest(`${ApiUrls.EUROPASTRY}/Documento?id=${id}`, {}).then((requestData) => requestData)
}

export const getUserInfo = (): Promise<GetUserInfoModel> => {
  return makeGetRequest(`${ApiUrls.EUROPASTRY}/Login/getuserinfo`, {}).then((requestData) => requestData.result)
}

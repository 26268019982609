import { getNoticesGroupsNotice } from 'api/requests'
import { Table } from 'Components/Table'
import { DEFAULT_PAGINATION_CONFIG } from 'Components/Table/Constants'
import { generateTableColumns } from 'Components/Table/GenerateColumns'
import { ResponseNoticeListModel } from 'domain/Notice'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { historicalNoticeFilterAtom } from 'recoil/atom'
import './index.css'

interface Props {
  groupsId: any
  pedidoId: any
}
export const InnerGroupTable = ({ groupsId, pedidoId }: Props) => {
  const [responseState, setResponseState] = useState<ResponseNoticeListModel>({
    message: '',
    result: {
      total: 0,
      datos: [],
      columnas: {
        avisos: [],
      },
    },
    status: 'loading',
  })
  const historicalNoticeFilter = useRecoilValue(historicalNoticeFilterAtom)

  const onChangeTable = async (pagination: any) => {
    setResponseState({ ...responseState, status: 'loading' })

    const updatedFilter: any = {
      ...historicalNoticeFilter,
      numPagina: pagination.current,
      numresultados: pagination.pageSize,
      grupoId: groupsId,
      pedidoId,
    }
    const response = await getNoticesGroupsNotice(updatedFilter)
    setResponseState(response)
  }
  useEffect(() => {
    onChangeTable({ ...historicalNoticeFilter, current: 1, pageSize: DEFAULT_PAGINATION_CONFIG.pageSize })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ width: '80vw' }}>
      <Table
        className="groupTable"
        columns={generateTableColumns(responseState.result.columnas?.avisos)}
        dataSource={responseState.result.datos}
        size="small"
        loading={responseState.status === 'loading'}
        onChange={onChangeTable}
        pagination={{
          ...DEFAULT_PAGINATION_CONFIG,
          total: responseState.result.total,
        }}
        rowClassName={(record, index) => {
          if (record.usuarioAceptacion === '' || record.usuarioAceptacion === null) {
            return 'row-received'
          }
          return 'row-pending'
        }}
        scroll={{ x: '125vw', y: '65vh' }}
        rowKey="id"
      />
    </div>
  )
}

import { ResponseOrderModel } from 'domain/Orders'

export const DEFAULT_FILTER = {
  numPagina: 1,
  numResultados: 20,
}

export const DEFAULT_TABLE_RESPONSE: ResponseOrderModel = {
  message: '',
  result: {
    total: 0,
    datos: [],
    columnas: {
      clienteComercial: [],
      clienteFacturacion: [],
      documentos: [],
      fechas: [],
      lineas: [],
      notas: [],
      pedidos: [],
      vEs: [],
      detalle: { datosPedido: [], magnitudesPedido: [], resumenContenedor: [] },
    },
  },
  status: 'loading',
}

export const DEFAULT_PAGINATION_CONFIG = {
  hideOnSinglePage: true,
  showSizeChanger: false,
  showTotal: (total: any) => `Total: ${total}`,
  pageSize: 20,
}

import { Checkbox, Descriptions, DescriptionsProps } from 'antd'
import { getDDMMYYYY } from 'Components/dates'
import { ColumnsBackendModel } from 'domain/Orders'
interface Props extends DescriptionsProps {
  title: string
  config: ColumnsBackendModel[]
  data: any
  column?: any
}
export const SectionList = ({ title, config, data, ...props }: Props) => {
  return (
    <Descriptions {...props} title={title} style={{ paddingBottom: '1.5em' }} bordered size="small">
      {config.map((header) => {
        let value = data[header.dataIndex as any]
        if (header.dataIndex.toLowerCase().includes('fecha')) {
          value = getDDMMYYYY(data[header.dataIndex as any])
        }
        return (
          <Descriptions.Item labelStyle={{ fontWeight: '500' }} label={header.title}>
            {value === true || value === false ? <Checkbox checked={value} disabled /> : value}
          </Descriptions.Item>
        )
      })}
    </Descriptions>
  )
}

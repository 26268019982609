import { PrivateRoute } from 'Components/PrivateRoute'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import { useRoutesHook } from './routes'
import Login from 'Components/MicrosoftAuth/Login'
import Logout from 'Components/MicrosoftAuth/Logout'

const RouterApp = () => {
  const routes = useRoutesHook()

  return (
    <Router>
      <Routes>
        {routes.map(({ path, Component }: any, index: number) => (
          <Route
            path={`/${path}`}
            key={index}
            element={
              <PrivateRoute key={index.toString() + '_component'}>
                <Component />
              </PrivateRoute>
            }
          />
        ))}
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />

        <Route path="*" element={<Navigate replace to="/login" />} />
      </Routes>
    </Router>
  )
}

export default RouterApp

import { ConfigProvider, theme } from 'antd'
import esES from 'antd/es/locale/es_ES'
import RouterApp from 'Components/Routes'
import { RecoilRoot } from 'recoil'
import './App.css'
import 'dayjs/locale/es'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import dayjs from 'dayjs'
import { oidcConfiguration } from 'Components/MicrosoftAuth/environmentOptions'
import updateLocale from 'dayjs/plugin/updateLocale'

dayjs.extend(updateLocale)

dayjs.updateLocale('es', {
  weekStart: 0,
})

const { compactAlgorithm } = theme

function App() {
  const pca = new PublicClientApplication(oidcConfiguration)

  return (
    <MsalProvider instance={pca}>
      <RecoilRoot>
        <ConfigProvider
          locale={esES}
          theme={{
            algorithm: [compactAlgorithm],
            token: {
              colorPrimary: '#3d4e44',
            },
          }}
        >
          <RouterApp />
        </ConfigProvider>
      </RecoilRoot>
    </MsalProvider>
  )
}

export default App

import { Layout, Menu } from 'antd'
import Sider from 'antd/es/layout/Sider'
import { Content } from 'antd/es/layout/layout'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Header from './Header'
import { useTokenStore } from './MicrosoftAuth/hooks.ts/standard/useToken'
import { NotAuthorized } from './NotAuthorized'
import { useRoutesHook } from './Routes/routes'

export const PrivateRoute = ({ children }: any) => {
  const [collapsed, setCollapsed] = useState(false)
  const location = useLocation()
  const routes = useRoutesHook()
  const { tokenValues } = useTokenStore()

  if (tokenValues === '') {
    return <NotAuthorized />
  }
  const defaultMenu = routes.findIndex((route) => route.path === location.pathname) || 0

  const items: any[] = routes
    .filter((item: any) => item.name)
    .map(({ path, name, icon }: any, index: number) => ({
      key: index.toString(),
      icon: <Link to={path}>{icon}</Link>,
      label: <Link to={path}>{name}</Link>,
    }))
  return (
    <div style={{ minHeight: '100vh', display: 'grid', gridTemplateRows: '52px 1fr' }}>
      <Header />
      <Layout>
        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
          <Menu theme="dark" defaultSelectedKeys={[defaultMenu.toString()]} mode="inline" items={items} />
        </Sider>

        <Content style={{ padding: '10px 20px', overflowY: 'auto', maxHeight: '90vh' }}>
          <div className="site-layout-content">{children}</div>
        </Content>
      </Layout>
    </div>
  )
}

import { CSSProperties, useEffect } from 'react'
import { useLogout } from './hooks.ts/useLogout'
import { Spin } from 'antd'
type LoadingSpinModel = { size?: 'small' | 'default' | 'large'; style?: CSSProperties }

const LoadingSpin = ({ size = 'default', style = {} }: LoadingSpinModel) => {
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', top: '0', ...style }}>
      <Spin size={size} style={{ margin: '0 auto', display: 'flex' }} />
    </div>
  )
}

const Logout = () => {
  const logout = useLogout()
  useEffect(() => {
    logout()
  }, [])

  return <LoadingSpin size="large" />
}

export default Logout

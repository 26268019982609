import { Tabs } from 'antd'
import { HistoricalOrder } from './Historical'
import { OrderList } from './OrderList'

enum OPTIONS {
  Seguimiento = 'Seguimiento',
  Historico = 'Histórico',
}
export const Orders = () => {
  return (
    <div>
      <Tabs defaultActiveKey={OPTIONS.Seguimiento}>
        <Tabs.TabPane tab={OPTIONS.Seguimiento} key={OPTIONS.Seguimiento}>
          <div style={{ maxWidth: '100%' }}>
            <OrderList />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={OPTIONS.Historico} key={OPTIONS.Historico}>
          <HistoricalOrder />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

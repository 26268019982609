import { BasicFilterModel } from 'domain/Orders'
import { GetUserInfoModel } from 'domain/User'
import { atom } from 'recoil'

export const historicalNoticeFilterAtom = atom<BasicFilterModel>({
  key: 'historicalNoticeFilterAtom',
  default: {},
})

export const userInfoAtom = atom<GetUserInfoModel>({
  key: 'userInfoAtom',
  default: {
    id: null,
    nombre: '',
    apellidos: '',
    correoElectronico: '',
    rolId: 0,
    rolDescription: '',
    permisos: [],
  },
})

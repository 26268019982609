import React, { useState } from 'react'
import { Resizable, ResizeCallbackData } from 'react-resizable'

const ResizableCell = (
  props: React.HTMLAttributes<any> & {
    onResize: (e: React.SyntheticEvent<Element>, data: ResizeCallbackData) => void
    onResizeStop: Function
    width: number
  }
) => {
  const [resizing, setResizing] = useState(false)
  const { onResize, onResizeStop, width, onClick, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResizeStart={() => setResizing(true)}
      onResizeStop={() => {
        setTimeout(() => {
          setResizing(false)
          onResizeStop()
        })
      }}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th
        onClick={(...args) => {
          if (!resizing && onClick) {
            onClick(...args)
          }
        }}
        {...restProps}
      />
    </Resizable>
  )
}

export default ResizableCell

import { generateTableColumns } from 'Components/Table/GenerateColumns'
import { getFormDateToYYYYMMDD } from 'Components/dates'
import { useForm } from 'antd/lib/form/Form'
import { getHistoricalOrdersByFilter } from 'api/requests'
import { OrderFilterModel, OrderFilterRequestModel, ResponseOrderModel } from 'domain/Orders'
import { useEffect, useState } from 'react'
import { DEFAULT_FILTER, DEFAULT_TABLE_RESPONSE } from '../../../Components/Table/Constants'
import { ButtonHideColumns } from '../OrderList/ButtonHideColumns'
import Filters from '../OrderList/Filters'
import { formatTableColumns, retardedToRequest } from '../OrderList/Helper'
import OrderTable from '../OrderList/Table'

export const HistoricalOrder = () => {
  const [form] = useForm()
  const [data, setData] = useState<ResponseOrderModel>({ ...DEFAULT_TABLE_RESPONSE })
  const [columns, setColumns] = useState<any[]>([])
  const [editedColumns, setEditedColumns] = useState<any[]>([])

  const loadData = async (filter: OrderFilterModel) => {
    setData({ ...data, status: 'loading' })
    const body: OrderFilterRequestModel = {
      ...filter,
      fechaFin: getFormDateToYYYYMMDD(filter.fechaFin),
      fechaInicio: getFormDateToYYYYMMDD(filter.fechaInicio),
      retrasado: retardedToRequest((filter as OrderFilterModel).retrasado),
      fechaNumSemana: getFormDateToYYYYMMDD(filter.fechaNumSemana),
    }
    const response = await getHistoricalOrdersByFilter(body)
    setData(response)
  }
  const onChangeTable = (pagination: any, filters: any, sorting: { order: 'ascend' | 'descend'; field?: string }) => {
    const filterValues = form.getFieldsValue()
    const updatedFilter: OrderFilterModel = {
      ...filterValues,
      numPagina: pagination.current,
      numresultados: pagination.pageSize,
      orderBy: sorting.field,
      orderByDescending: sorting.order === 'descend',
    }
    loadData(updatedFilter)
    form.setFieldsValue(updatedFilter)
  }
  // TODO posiblemente quitar
  useEffect(() => {
    loadData({
      fechaFin: undefined,
      fechaInicio: undefined,
      retrasado: 'all',
      clienteId: undefined,
      fechaNumSemana: null,
      ...DEFAULT_FILTER,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    const updatedColumns = formatTableColumns(generateTableColumns(data.result.columnas.pedidos)) || []
    setEditedColumns(updatedColumns.map((item) => item.dataIndex))
    setColumns(updatedColumns || [])
  }, [data.result.columnas])

  return (
    <div>
      <Filters
        form={form}
        onSearch={loadData}
        actions={<ButtonHideColumns columns={editedColumns} setColumns={setEditedColumns} options={columns} />}
      />

      <OrderTable
        data={data}
        onChange={onChangeTable}
        columns={columns.filter((column) => editedColumns.includes(column.dataIndex))}
      />
    </div>
  )
}

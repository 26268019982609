import { BrowserCacheLocation } from '@azure/msal-browser'

export const ENVIRONMENT_CONFIG = {
  CLIENT_ID: process.env.REACT_APP_AUTH_CLIENT_ID || '',
  REDIRECT_URI: process.env.REACT_APP_AUTH_REDIRECT_URI || '',
  AUTH_TENANT: process.env.REACT_APP_AUTH_TENANT_URL || '',
  TOKEN_NAME: process.env.REACT_APP_TOKEN_NAME || '',
  API: process.env.REACT_APP_API || '',
  REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI: process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI || '',
}
export const oidcConfiguration = {
  auth: {
    clientId: ENVIRONMENT_CONFIG.CLIENT_ID,
    authority: ENVIRONMENT_CONFIG.AUTH_TENANT,
    redirectUri: ENVIRONMENT_CONFIG.REDIRECT_URI,
    postLogoutRedirectUri: ENVIRONMENT_CONFIG.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI, // TODO
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
}

export const MICROSOFT_OPTIONS = {
  organizationName: '.com',
  applicationName: '',
}

import { useMsal } from '@azure/msal-react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import tokenManager from '../TokenManager'
import { useTokenStore } from '../hooks.ts/standard/useToken'
import { LoginCard, LoginContainer, LoginTitle, MicrosoftLoginButton } from './styles'

const Login = () => {
  const navigate = useNavigate()
  const { tokenValues: currentToken, setTokenValues: setCurrentToken } = useTokenStore()
  const { instance } = useMsal()

  const handleLogin = () => {
    instance.loginPopup().then(({ account, accessToken }) => {
      if (account) {
        setCurrentToken(accessToken)
        tokenManager.setTokens(accessToken, '')
      }
    })
  }

  useEffect(() => {
    if (currentToken !== '') {
      navigate('/pedidos')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentToken])
  return (
    <LoginContainer>
      <LoginCard>
        {/* <Logo src="/logo1.png" alt="Login celsa" /> */}
        <LoginTitle>Iniciar sesión</LoginTitle>
        <MicrosoftLoginButton type="primary" onClick={handleLogin}>
          Iniciar sesión con Microsoft
        </MicrosoftLoginButton>
      </LoginCard>
    </LoginContainer>
  )
}

export default Login

import { selector } from 'recoil'
import { userInfoAtom } from './atom'

export const permissionsSelector = selector({
  key: 'permissionsSelector',
  get: ({ get }) => {
    const userInfo = get(userInfoAtom)
    return userInfo.permisos.map(({ codigo }) => codigo)
  },
})

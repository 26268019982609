import { Button, Tabs } from 'antd'
import { getNoticeGroupsOptions } from 'api/requests'
import { OrderOptionState } from 'domain/Orders'
import { useEffect, useState } from 'react'
import { Historical } from './Historical'
import { NoticeList } from './NoticeList'
enum OPTIONS {
  Avisos = 'Avisos pendiente de confirmación',
  Historico = 'Avisos',
}
export const Notice = () => {
  const [stateOptions, setStateOptions] = useState<OrderOptionState[]>([])

  useEffect(() => {
    const loadOrderOptions = async () => {
      const response = await getNoticeGroupsOptions()
      setStateOptions(response.result.datos)
    }
    loadOrderOptions()
  }, [])

  return (
    <div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane
          tab={
            <Button style={{ backgroundColor: '#fd5d5d', color: 'white', paddingRight: '2em' }}>
              {OPTIONS.Avisos}
            </Button>
          }
          key={OPTIONS.Avisos}
        >
          <NoticeList stateOptions={stateOptions} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={OPTIONS.Historico} key={OPTIONS.Historico}>
          <Historical stateOptions={stateOptions} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

import { DownloadOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import { getDownloadFile } from 'api/requests'
import { useState } from 'react'

export const DownloadDocument = ({ documentId }: { documentId: number }) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false)

  const downloadFile = async (documentId: number) => {
    try {
      setIsDownloading(true)
      const { documento, nombre } = await getDownloadFile(documentId)
      if (documento && nombre) {
        const linkSource = `data:application/pdf;base64,${documento}`
        const downloadLink = document.createElement('a')

        downloadLink.href = linkSource
        downloadLink.download = nombre
        downloadLink.click()
      }
    } catch (err) {
      message.error('Error al descargar')
    } finally {
      setIsDownloading(false)
    }
  }
  return (
    <Button
      loading={isDownloading}
      icon={<DownloadOutlined onClick={() => downloadFile(documentId)} />}
      type="text"
      shape="circle"
    />
  )
}

export const downloadDocumentColumn = {
  dataIndex: 'downloadDocument',
  width: 60,
  align: 'center',
  render: (value: any, record: { id: number }) => <DownloadDocument documentId={record.id} />,
}

import { generateTableColumns } from 'Components/Table/GenerateColumns'
import { getFormDateToYYYYMMDD } from 'Components/dates'
import { message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { getOrdersByFilter, getSabana, postExportOrdersByFilter } from 'api/requests'
import { OrderFilterModel, ResponseOrderModel } from 'domain/Orders'
import { useEffect, useState } from 'react'
import { DEFAULT_FILTER, DEFAULT_TABLE_RESPONSE } from '../../../Components/Table/Constants'
import { ButtonHideColumns } from './ButtonHideColumns'
import Filters from './Filters'
import { formatTableColumns, retardedToRequest } from './Helper'
import OrderTable from './Table'

export const OrderList = () => {
  const [form] = useForm()
  const [data, setData] = useState<ResponseOrderModel>({ ...DEFAULT_TABLE_RESPONSE })
  const [messageApi, contextHolder] = message.useMessage()
  const [isLoadingExport, setIsLoadingExport] = useState(false)
  const [isLoadingSabana, setIsLoadingSabana] = useState(false)
  const [columns, setColumns] = useState<any[]>([])
  const [editedColumns, setEditedColumns] = useState<any[]>([])

  const [selectedRowId, setSelectedRowId] = useState<number>(-1)

  const generateSabana = async () => {
    try {
      setIsLoadingSabana(true)
      const response = await getSabana(selectedRowId)
      setSelectedRowId(-1)
      setIsLoadingSabana(false)
      message.info(response.message)
    } catch (err) {
      message.error('Error al enviar')
      setIsLoadingSabana(false)
    }
  }
  const loadData = async (filter: OrderFilterModel) => {
    setData({ ...data, status: 'loading' })
    const response = await getOrdersByFilter({
      ...filter,
      fechaFin: getFormDateToYYYYMMDD(filter.fechaFin),
      fechaInicio: getFormDateToYYYYMMDD(filter.fechaInicio),
      retrasado: retardedToRequest((filter as OrderFilterModel).retrasado),
      fechaNumSemana: getFormDateToYYYYMMDD(filter.fechaNumSemana),
    })
    setData(response)
  }
  const onChangeTable = (pagination: any, filters: any, sorting: { order: 'ascend' | 'descend'; field?: string }) => {
    const filterValues = form.getFieldsValue()
    const updatedFilter: OrderFilterModel = {
      ...filterValues,
      numPagina: pagination.current,
      numresultados: pagination.pageSize,
      orderBy: sorting.field,
      orderByDescending: sorting.order === 'descend',
    }
    loadData(updatedFilter)
    form.setFieldsValue(updatedFilter)
  }
  const handleExport = async () => {
    try {
      setIsLoadingExport(true)
      const filterValues = form.getFieldsValue()
      postExportOrdersByFilter({
        ...filterValues,
        fechaFin: getFormDateToYYYYMMDD(filterValues.fechaFin),
        fechaInicio: getFormDateToYYYYMMDD(filterValues.fechaInicio),
        retrasado: retardedToRequest((filterValues as OrderFilterModel).retrasado),
        fechaNumSemana: getFormDateToYYYYMMDD(filterValues.fechaNumSemana),
      })
      messageApi.open({
        type: 'info',
        content: 'En unos minutos recibirás el documento excel en tu bandeja de entrada',
      })
      setIsLoadingExport(false)
    } catch (err) {
      setIsLoadingExport(false)
      messageApi.open({
        type: 'error',
        content: 'Error al exportar',
      })
    }
  }
  useEffect(() => {
    const updatedColumns = formatTableColumns(generateTableColumns(data.result.columnas.pedidos)) || []
    setEditedColumns(updatedColumns.map((item) => item.dataIndex))
    setColumns(updatedColumns || [])
  }, [data.result.columnas])

  useEffect(() => {
    loadData({
      fechaFin: undefined,
      fechaInicio: undefined,
      retrasado: 'all',
      clienteId: undefined,
      fechaNumSemana: null,
      ...DEFAULT_FILTER,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div>
      {contextHolder}
      <div>
        <Filters
          form={form}
          onSearch={loadData}
          onExport={handleExport}
          isLoadingExport={isLoadingExport}
          isLoadingSabana={isLoadingSabana}
          onGenerateSabana={generateSabana}
          itemSelected={selectedRowId}
          actions={<ButtonHideColumns columns={editedColumns} setColumns={setEditedColumns} options={columns} />}
        />

        <OrderTable
          data={data}
          onChange={onChangeTable}
          selectedRowId={selectedRowId}
          setSelectedRowId={setSelectedRowId}
          columns={columns.filter((column) => editedColumns.includes(column.dataIndex))}
        />
      </div>
    </div>
  )
}

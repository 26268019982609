import React, { useEffect, useState } from 'react'
import { Collapse, Pagination, Typography } from 'antd'
import { getNoticeOrders } from 'api/requests'
import NoticeGroupAccordion from './NoticeGroupAccordion'
import { DEFAULT_PAGINATION_CONFIG } from 'Components/Table/Constants'
import { useRecoilValue } from 'recoil'
import { historicalNoticeFilterAtom } from 'recoil/atom'

const { Panel } = Collapse
const { Title } = Typography

interface ClientModel {
  id: any
  nombre: string
}
interface Props {
  clientId: ClientModel[]
}

const OrdersAccordion = ({ clientId }: Props) => {
  const [ordersResponse, setOrdersResponse] = useState<any>({
    message: '',
    result: {
      total: 0,
      datos: [],
    },
    status: 'loading',
  })
  const historicalNoticeFilter = useRecoilValue(historicalNoticeFilterAtom)
  const [currentPage, setCurrentPage] = useState(1)
  const orders: any[] = ordersResponse.result.datos

  const onChangePagination = async (pagination: any) => {
    setOrdersResponse({ ...ordersResponse, status: 'loading' })

    const updatedFilter: any = {
      ...historicalNoticeFilter,
      numPagina: pagination.current,
      numresultados: pagination.pageSize,
      clienteId: clientId,
    }
    const orders = await getNoticeOrders(updatedFilter)
    setOrdersResponse(orders)
    setCurrentPage(pagination.current)
  }

  useEffect(() => {
    const loadData = async () => {
      const orders = await getNoticeOrders({ ...historicalNoticeFilter, clienteId: clientId, numPagina: 1 })
      setOrdersResponse(orders)
    }
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div style={{ paddingBottom: '3em' }}>
      <Title level={5} style={{ paddingLeft: '0.5em', marginTop: 0 }}>
        Referencia de pedidos
      </Title>
      <Collapse defaultActiveKey="1">
        {orders.map((client) => {
          return (
            <Panel header={client.nombre} key={client.id}>
              <NoticeGroupAccordion pedidoId={client.id} />
            </Panel>
          )
        })}
      </Collapse>
      <Pagination
        style={{ right: 0, position: 'absolute', paddingTop: '1em' }}
        total={ordersResponse.result.total}
        onChange={onChangePagination}
        {...DEFAULT_PAGINATION_CONFIG}
        current={currentPage}
      />
    </div>
  )
}

export default OrdersAccordion

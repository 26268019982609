import { Button, Card, Result } from 'antd'
import { useNavigate } from 'react-router-dom'

export const NotAuthorized = () => {
  const navigate = useNavigate()

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '45vh' }}>
      <Card>
        <Result
          title="403"
          status="info"
          subTitle="No estás autorizado para acceder a esta página"
          extra={
            <Button type="primary" onClick={() => navigate('/login')}>
              Volver
            </Button>
          }
        />
      </Card>
    </div>
  )
}

import { Button, Card } from 'antd'
import styled from 'styled-components'

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* background-color: #e50d2e; */
`

export const LoginCard = styled(Card)`
  width: 300px;
  height: 300px;
  text-align: center;
`

export const LoginTitle = styled.h1`
  font-size: 24px;
  margin-bottom: 75px;
`

export const Logo = styled.img`
  width: 150px;
  height: auto;
  margin-bottom: 24px;
`
export const MicrosoftLoginButton = styled(Button)`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  height: 3em;
`

import { SearchOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  Select,
  SelectProps,
  Space,
  Tooltip,
} from 'antd'
import { useWatch } from 'antd/lib/form/Form'
import { getOrderState } from 'api/requests'
import { LocalDatePicker } from 'Components/LocalDatePicker'
import { OrderFilterRequestModel } from 'domain/Orders'
import { useEffect, useState } from 'react'
import { nameof } from 'ts-simple-nameof'
import { DEFAULT_FILTER } from '../../../Components/Table/Constants'
import { STYLES } from './syles'
import './style.css'
import Actions from './Actions'

const FORM_ITEM_STYLE = { padding: '0' }
interface Props {
  form: FormInstance<OrderFilterRequestModel>
  onSearch: (values: any) => void
  onExport?: any
  isLoadingExport?: boolean
  onGenerateSabana?: () => void
  itemSelected?: number
  isLoadingSabana?: boolean
  actions: any
}
const Filters = ({
  form,
  onSearch,
  onExport,
  isLoadingExport,
  onGenerateSabana,
  isLoadingSabana,
  itemSelected,
  actions,
}: Props) => {
  const [stateOptions, setStateOptions] = useState<SelectProps['options']>([])
  const estado = useWatch(
    nameof<OrderFilterRequestModel>((item) => item.estado),
    form
  )
  const fechaInicio = useWatch(
    nameof<OrderFilterRequestModel>((item) => item.fechaInicio),
    form
  )
  const fechaFin = useWatch(
    nameof<OrderFilterRequestModel>((item) => item.fechaFin),
    form
  )
  const handleSelectAllStates = () => {
    form.setFieldValue(
      'estados',
      stateOptions?.map(({ value }) => value)
    )
  }
  useEffect(() => {
    const loadOrderOptions = async () => {
      const response = await getOrderState()
      setStateOptions(response.result.datos.map(({ id, descripcion }) => ({ value: id, label: descripcion })) || [])
    }
    loadOrderOptions()
  }, [])

  return (
    <Row justify="space-between" style={{ alignItems: 'end', paddingBlockEnd: '1em' }}>
      <Col span={24}>
        <Form
          initialValues={{ ...DEFAULT_FILTER, estado: null, retrasado: 'all' }}
          form={form}
          name="horizontal_login"
          layout="inline"
          onFinish={(values) => onSearch({ ...values, ...DEFAULT_FILTER })}
          className="filter--form"
        >
          <div className="filter--form__filter-row1">
            <Form.Item name={nameof<OrderFilterRequestModel>((item) => item.textoBusqueda)} style={FORM_ITEM_STYLE}>
              <Input
                suffix={<SearchOutlined style={{ color: '#ccc' }} />}
                placeholder="Buscar"
                style={{ width: '15em' }}
              />
            </Form.Item>
            <Form.Item>y/o</Form.Item>
            <Tooltip title="Rango de fechas del estado">
              <div className="filter--group">
                <Form.Item label="" name={nameof<OrderFilterRequestModel>((item) => item.estado)}>
                  <Select
                    style={{ width: '12em' }}
                    placeholder="Seleccionar estado"
                    options={[{ label: 'Seleccionar estado', value: null }, ...(stateOptions ? stateOptions : [])]}
                  />
                </Form.Item>
                <Form.Item
                  name={nameof<OrderFilterRequestModel>((item) => item.fechaInicio)}
                  label=""
                  validateStatus={estado !== null ? (fechaInicio ? 'success' : 'error') : 'success'}
                >
                  <LocalDatePicker placeholder="Fecha inicial" disabled={estado === null} />
                </Form.Item>
                <Form.Item
                  name={nameof<OrderFilterRequestModel>((item) => item.fechaFin)}
                  label=""
                  validateStatus={estado !== null ? (fechaFin ? 'success' : 'error') : 'success'}
                >
                  <LocalDatePicker placeholder="Fecha final" disabled={estado === null} />
                </Form.Item>
              </div>
            </Tooltip>

            <Form.Item
              label="Estados"
              name={nameof<OrderFilterRequestModel>((item) => item.estados)}
              style={{ width: '28vw' }}
            >
              <Select
                placeholder="Seleccionar estados"
                mode="multiple"
                allowClear
                style={{ minWidth: '100%' }}
                maxTagCount="responsive"
                options={stateOptions}
                showArrow={true}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ marginTop: 0 }} />
                    <Button type="link" style={{ width: '100%' }} onClick={handleSelectAllStates}>
                      Seleccionar todos
                    </Button>
                  </>
                )}
              />
            </Form.Item>
          </div>
          <div className="filter--form__filter-row2">
            <Tooltip title="Número de semana de fecha de salida deseada">
              <Form.Item name={nameof<OrderFilterRequestModel>((item) => item.fechaNumSemana)} style={FORM_ITEM_STYLE}>
                <DatePicker placeholder="Número de semana" picker="week" style={{ width: '15em' }} />
              </Form.Item>
            </Tooltip>
            <Form.Item
              name={nameof<OrderFilterRequestModel>((item) => item.retrasado)}
              label="Pedidos"
              style={FORM_ITEM_STYLE}
            >
              <Radio.Group optionType="button" buttonStyle="solid">
                <Radio.Button value="all">Todos</Radio.Button>
                <Radio.Button value="isDelayed">Con retraso</Radio.Button>
                <Radio.Button value="notDelayed">Sin retraso</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item style={{ ...FORM_ITEM_STYLE }}>
              <Button type="primary" htmlType="submit" style={STYLES.search}>
                Buscar
              </Button>
            </Form.Item>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Space>
                {actions}

                {typeof onExport !== 'undefined' && (
                  <Actions
                    onExport={onExport}
                    isLoadingExport={isLoadingExport}
                    onGenerateSabana={onGenerateSabana}
                    isLoadingSabana={isLoadingSabana}
                    itemSelected={itemSelected ?? -1}
                  />
                )}
              </Space>
            </div>
          </div>
        </Form>
      </Col>
    </Row>
  )
}

export default Filters

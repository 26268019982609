import { FileExcelOutlined, FileOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'

import './style.css'
import { useRecoilValue } from 'recoil'
import { permissionsSelector } from 'recoil/selector'
import { PERMISSIONS } from 'permissions'

interface Props {
  onExport?: any
  isLoadingExport?: boolean
  onGenerateSabana?: () => void
  itemSelected: number
  isLoadingSabana?: boolean
}
const Actions = ({ onExport, isLoadingExport, onGenerateSabana, isLoadingSabana, itemSelected }: Props) => {
  const isItemSelected = itemSelected !== -1
  const permissions = useRecoilValue(permissionsSelector)
  const showSabana = permissions.includes(PERMISSIONS.WEB_CREAR_SABANA)
  const SabanaButton = () => {
    if (showSabana === false) return <></>
    return (
      <Button
        icon={<FileOutlined />}
        type="primary"
        style={{ ...(isItemSelected ? { backgroundColor: '#c7c3b5', color: 'black' } : {}) }}
        onClick={onGenerateSabana}
        loading={isLoadingSabana}
        disabled={!isItemSelected}
      >
        Crear Sábana
      </Button>
    )
  }

  return (
    <div className="action__buttons">
      {isItemSelected ? (
        <SabanaButton />
      ) : (
        <Tooltip title="Selecciona un pedido">
          <SabanaButton />
        </Tooltip>
      )}

      <Button icon={<FileExcelOutlined />} type="primary" onClick={onExport} loading={isLoadingExport}>
        Exportar Excel
      </Button>
    </div>
  )
}

export default Actions

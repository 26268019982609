import { UserOutlined } from '@ant-design/icons'
import { useLogout } from 'Components/MicrosoftAuth/hooks.ts/useLogout'
import { Avatar, List, Popover, Space } from 'antd'
import Logout from '../Logout'
import { AvatarStyle, ListItemStyle } from './style'
import { useUserInfo } from 'hooks/useUserInfo'

function UserMenuOptions() {
  const logout = useLogout()
  const userInfo = useUserInfo()
  const items = [
    {
      label: <Logout onLogout={logout}>Logout</Logout>,
      key: 'logout',
      hover: 2,
    },
  ]

  const list = (
    <List
      dataSource={items}
      renderItem={(item) => <ListItemStyle hover={item.hover}>{item.label}</ListItemStyle>}
    ></List>
  )

  return (
    <>
      <Space>
        <div style={{ width: 'auto' }}>
          <div style={{ fontWeight: 'bolder', color: 'white' }}> {userInfo.nombre}</div>
        </div>
        <Popover placement="bottomLeft" content={list} trigger={['click']} showArrow={false}>
          <AvatarStyle>
            <Avatar icon={<UserOutlined />}></Avatar>
          </AvatarStyle>
        </Popover>
      </Space>
    </>
  )
}

export default UserMenuOptions

import { Collapse, Pagination, Typography } from 'antd'
import { getNoticeGroups } from 'api/requests'
import { ManageStatus } from 'Components/ManageStatus/ManageStatus'
import { DEFAULT_PAGINATION_CONFIG } from 'Components/Table/Constants'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { historicalNoticeFilterAtom } from 'recoil/atom'
import { converRequestStateToState } from './Helps'
import { InnerGroupTable } from './InnerGroupTable'

const { Panel } = Collapse
const { Title } = Typography

interface ClientModel {
  id: any
  nombre: string
}
interface Props {
  pedidoId: ClientModel[]
}

const NoticeGroupAccordion = ({ pedidoId }: Props) => {
  const [ordersResponse, setOrdersResponse] = useState<any>({
    message: '',
    result: {
      total: 0,
      datos: [],
    },
    status: 'loading',
  })
  const historicalNoticeFilter = useRecoilValue(historicalNoticeFilterAtom)

  const orders: any[] = ordersResponse.result.datos
  const [currentPage, setCurrentPage] = useState(1)

  const onChangePagination = async (pagination: any) => {
    setOrdersResponse({ ...ordersResponse, status: 'loading' })

    const updatedFilter: any = {
      ...historicalNoticeFilter,
      numPagina: pagination.current,
      numresultados: pagination.pageSize,
      pedidoId: pedidoId,
    }
    const orders = await getNoticeGroups(updatedFilter)
    setOrdersResponse(orders)
    setCurrentPage(pagination.current)
  }

  useEffect(() => {
    const loadData = async () => {
      const groups = await getNoticeGroups({ ...historicalNoticeFilter, pedidoId, numPagina: 1 })
      setOrdersResponse(groups)
    }
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <ManageStatus
      status={converRequestStateToState(ordersResponse.status)}
      styleState={{ loading: { height: '20em' } }}
      fullScreen
    >
      <div style={{ paddingBottom: '3em' }}>
        <Title level={5} style={{ paddingLeft: '0.5em', marginTop: 0 }}>
          Grupos
        </Title>
        <Collapse defaultActiveKey="1">
          {orders.map((client) => {
            return (
              <Panel header={client.nombre} key={client.id}>
                <InnerGroupTable groupsId={client.id} pedidoId={pedidoId} />
              </Panel>
            )
          })}
        </Collapse>
        <Pagination
          style={{ right: 0, position: 'absolute', paddingTop: '1em' }}
          total={ordersResponse.result.total}
          onChange={onChangePagination}
          current={currentPage}
          {...DEFAULT_PAGINATION_CONFIG}
        />
      </div>
    </ManageStatus>
  )
}

export default NoticeGroupAccordion

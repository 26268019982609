import { Button, Result, Spin } from 'antd'
import { Link } from 'react-router-dom'
import { STYLES } from './Styles'

interface Props {
  children?: React.ReactNode
  status: 'loading' | 'error' | 'success'
  fullScreen?: boolean
  styleState?: { error?: any; loading?: any }
}
export const ManageStatus = ({ children, status, fullScreen = false, styleState }: Props) => {
  if (status === 'error') {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        style={{ ...styleState?.error }}
        extra={
          <Link to={'/'}>
            <Button type="primary">Back Home</Button>
          </Link>
        }
      />
    )
  }
  if (status === 'loading') {
    const spinProps: any = fullScreen ? { style: { ...STYLES.FULLSCREEN }, size: 'large' } : {}
    return <Spin {...spinProps} {...styleState?.loading} tip="Loading..." />
  }

  if (status === 'success') {
    return <>{children}</>
  }
  return <></>
}

import { message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { getCheckedSelected, getNoticeByFilter } from 'api/requests'
import { getFormDateToYYYYMMDD } from 'Components/dates'
import { DEFAULT_FILTER } from 'Components/Table/Constants'
import { CheckSelectedModel, ResponseNoticeListModel, SelectedNoticeTableModel } from 'domain/Notice'
import { BasicFilterModel, OrderOptionState } from 'domain/Orders'
import { useEffect, useState } from 'react'
import Filters from './Filters'
import NoticeTable from './Table'

export const DEFAULT_TABLE_RESPONSE: ResponseNoticeListModel = {
  message: '',
  result: {
    total: 0,
    datos: [],
    columnas: {
      avisos: [],
    },
  },
  status: 'loading',
}
interface Props {
  stateOptions: OrderOptionState[]
}
export const NoticeList = ({ stateOptions }: Props) => {
  const [form] = useForm()
  const [data, setData] = useState<ResponseNoticeListModel>({ ...DEFAULT_TABLE_RESPONSE })
  const [selectedState, setSelectedState] = useState<SelectedNoticeTableModel>({
    isAllSelected: false,
    selectedRows: [],
  })
  const loadData = async (filter: BasicFilterModel) => {
    setData({ ...data, status: 'loading' })
    const response = await getNoticeByFilter({
      ...filter,
      fechaFin: getFormDateToYYYYMMDD(filter.fechaFin),
      fechaInicio: getFormDateToYYYYMMDD(filter.fechaInicio),
    })
    setData(response)
    setSelectedState({
      isAllSelected: false,
      selectedRows: [],
    })
  }
  const onChangeTable = (pagination: any, filters: any, sorting: { order: 'ascend' | 'descend'; field?: string }) => {
    const filterValues = form.getFieldsValue()
    const updatedFilter: BasicFilterModel = {
      ...filterValues,
      numPagina: pagination.current,
      numresultados: pagination.pageSize,
      orderBy: sorting.field,
      orderByDescending: sorting.order === 'descend',
    }
    loadData(updatedFilter)
    form.setFieldsValue(updatedFilter)
  }

  const sendSelected = async (selected: SelectedNoticeTableModel | true) => {
    try {
      setData({ ...data, status: 'loading' })
      const filterValues = form.getFieldsValue()
      let selectedProps: CheckSelectedModel
      if (typeof selected === 'boolean') {
        selectedProps = { ...filterValues, marcarTodos: true }
      } else {
        selectedProps = {
          ...filterValues,
          avisosMarcar: selected.selectedRows.map((item) => item.id),
          marcarTodos: false,
        }
      }
      await getCheckedSelected({
        ...selectedProps,
        fechaFin: getFormDateToYYYYMMDD(selectedProps.fechaFin),
        fechaInicio: getFormDateToYYYYMMDD(selectedProps.fechaInicio),
      })
      const updatedFilter: BasicFilterModel = {
        ...filterValues,
        ...DEFAULT_FILTER,
      }
      await loadData(updatedFilter)
      message.success('Se ha enviado correctamente')
    } catch (err) {
      setData({ ...data, status: 'Ok' })
    }
  }
  useEffect(() => {
    loadData(DEFAULT_FILTER)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Filters
        stateOptions={stateOptions}
        form={form}
        onSearch={loadData}
        sendAll={() => sendSelected(true)}
        data={data}
      />
      <NoticeTable
        data={data}
        onChange={onChangeTable}
        sendSelected={sendSelected}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
      />
    </div>
  )
}

import { Tabs } from 'antd'
import { SectionList } from 'Components/SectionList'
import { Table } from 'Components/Table'
import { generateTableColumns } from 'Components/Table/GenerateColumns'
import { GroupOrderModel, OrderColumnsModel } from 'domain/Orders'
import { downloadDocumentColumn } from './DownloadDocument'
import { STYLES } from './syles'
import VesTable from './VesTable'

enum OPTIONS {
  Ves = 'VEs',
  Detalles = 'Detalles',
  Fechas = 'Fechas',
  Documentos = 'Documentos',
  Cliente = 'Cliente',
  Notas = 'Notas',
}
interface Props {
  record: GroupOrderModel
  columns: OrderColumnsModel
}
export const InlineRow = ({ record, columns }: Props) => {
  return (
    <div style={{ width: '90vw' }}>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={OPTIONS.Ves} key={OPTIONS.Ves}>
          <div style={STYLES.wrapperSectionTable}>
            <VesTable orders={record.vEs} columns={columns} />
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane tab={OPTIONS.Detalles} key={OPTIONS.Detalles}>
          <div style={STYLES.wrapperSectionTable}>
            <div style={{ width: '80vw', ...STYLES.sectionPaddings }}>
              <SectionList
                title="DATOS DEL PEDIDO"
                data={record}
                config={columns.detalle.datosPedido}
                column={{ xs: 2, sm: 2, md: 2 }}
                labelStyle={{ width: '12em' }}
                contentStyle={{ minWidth: '25em' }}
              />
              <SectionList
                title="MAGNITUDES DEL PEDIDO"
                data={record}
                config={columns.detalle.magnitudesPedido}
                column={{ xs: 2, sm: 2, md: 2 }}
                labelStyle={{ width: '12em' }}
                contentStyle={{ minWidth: '25em' }}
              />
              <SectionList
                title="RESUMEN DEL CONTENEDOR"
                data={record}
                config={columns.detalle.resumenContenedor}
                column={{ xs: 2, sm: 2, md: 2 }}
                labelStyle={{ width: '12em' }}
                contentStyle={{ minWidth: '25em' }}
              />
            </div>
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane tab={OPTIONS.Fechas} key={OPTIONS.Fechas}>
          <div style={{ ...STYLES.wrapperSectionTable, paddingBottom: '2em' }}>
            <Table
              columns={generateTableColumns(columns.fechas)}
              style={{ ...STYLES.sectionTablePaddings, width: '60em' }}
              size="small"
              dataSource={record.fechas}
              rowKey={'id'}
              pagination={false}
            />
          </div>
        </Tabs.TabPane>
        {record.documentos && record.documentos.length > 0 && (
          <Tabs.TabPane tab={OPTIONS.Documentos} key={OPTIONS.Documentos}>
            <div style={STYLES.wrapperSectionTable}>
              <Table
                columns={[...generateTableColumns(columns.documentos), downloadDocumentColumn]}
                style={{ ...STYLES.sectionTablePaddings, width: '80vw' }}
                size="small"
                dataSource={record.documentos}
                rowKey={'id'}
              />
            </div>
          </Tabs.TabPane>
        )}
        <Tabs.TabPane tab={OPTIONS.Cliente} key={OPTIONS.Cliente}>
          <div style={{ backgroundColor: 'white', width: '80vw' }}>
            <div style={{ ...STYLES.sectionPaddings, width: '80em' }}>
              <SectionList
                title="Cliente Comercial"
                data={record.clienteComercial}
                config={columns.clienteComercial}
                column={{ xs: 2, sm: 2, md: 2 }}
                labelStyle={{ width: '10em' }}
                contentStyle={{ width: '50em' }}
              />
              <SectionList
                title="Cliente Facturación"
                data={record.clienteComercial.clienteFacturacion}
                config={columns.clienteFacturacion}
                column={{ xs: 2, sm: 2, md: 2 }}
                labelStyle={{ width: '10em' }}
              />
            </div>
          </div>
        </Tabs.TabPane>
        {record.notas && record.notas.length > 0 && (
          <Tabs.TabPane tab={OPTIONS.Notas} key={OPTIONS.Notas}>
            <div style={STYLES.wrapperSectionTable}>
              <Table
                columns={generateTableColumns(columns.notas)}
                style={{ ...STYLES.sectionTablePaddings, width: '80vw' }}
                size="small"
                dataSource={record.notas}
                rowKey={'id'}
              />
            </div>
          </Tabs.TabPane>
        )}
      </Tabs>
    </div>
  )
}

import { Button, Card } from 'antd'
import { Table } from 'Components/Table'
import { DEFAULT_PAGINATION_CONFIG } from 'Components/Table/Constants'
import { generateTableColumns } from 'Components/Table/GenerateColumns'
import { SelectedNoticeTableModel } from 'domain/Notice'
import React from 'react'

interface GroupNoticeModel {
  key: any
  type: string
  creation: string
  message: string
  country: string
  ve: string
  group: string
  refOrder: string
  client: string
  id: any
}

interface Props {
  data: any
  onChange: any
  sendSelected: (values: SelectedNoticeTableModel) => void
  selectedState: SelectedNoticeTableModel
  setSelectedState: (values: SelectedNoticeTableModel) => void
}
const NoticeTable = ({ data, onChange, sendSelected, setSelectedState, selectedState }: Props) => {
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], currentSelectedRows: any[]) => {
      setSelectedState({ ...selectedState, selectedRows: currentSelectedRows })
    },
    getCheckboxProps: (record: GroupNoticeModel) => ({
      disabled: record.key === 'Disabled User', // Column configuration not to be checked
      name: record.key,
    }),
    onSelectAll: (isPressed: boolean, selectedRows: any) => {
      setSelectedState({ isAllSelected: isPressed, selectedRows })
    },
  }
  return (
    <div>
      <Card style={{ height: '2.5em' }} bodyStyle={{ paddingTop: '0.4em' }}>
        {selectedState.selectedRows.length > 0 && (
          <div>
            Se han seleccionado {selectedState.selectedRows.length} elementos
            <Button size="small" type="link" onClick={() => sendSelected(selectedState)}>
              Confirmar Seleccionados
            </Button>
          </div>
        )}
      </Card>

      <Table
        columns={generateTableColumns(data.result.columnas?.avisos)}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedState.selectedRows.map((item) => item.id),
          ...rowSelection,
        }}
        dataSource={data.result.datos}
        size="small"
        loading={data.status === 'loading'}
        onChange={onChange}
        pagination={{
          ...DEFAULT_PAGINATION_CONFIG,
          total: data.result.total,
        }}
        rowKey="id"
        scroll={{ x: '125vw', y: '65vh' }}
      />
    </div>
  )
}
export default NoticeTable

import { ENVIRONMENT_CONFIG } from 'Components/MicrosoftAuth/environmentOptions'
import { tokenAtom } from 'Components/MicrosoftAuth/store/atom'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'

export const useTokenStore = () => {
  const [tokenValues, setToken] = useRecoilState(tokenAtom)
  useEffect(() => {
    localStorage.getItem('token')
    const storageToken = localStorage.getItem(ENVIRONMENT_CONFIG.TOKEN_NAME)
    setToken(storageToken || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    tokenValues,
    setTokenValues: (currentValue: string) => {
      localStorage.setItem(ENVIRONMENT_CONFIG.TOKEN_NAME, currentValue)
      setToken(currentValue)
    },
  }
}

export const STYLES = {
  search: {
    width: '8em',
  },
  sectionPaddings: {
    paddingLeft: '1em',
    paddingTop: '1em',
  },
  sectionTablePaddings: {
    paddingTop: '1em',
  },
  wrapperSectionTable: { backgroundColor: 'white', width: '100%' },
}

import { Table as TableAntd, TableProps } from 'antd'
import { ColumnType } from 'antd/es/table'
import { useEffect, useState } from 'react'
import ResizableCell from './ResizableCell'

export const Table = (props: TableProps<any>) => {
  const [columns, setColumns] = useState<ColumnType<object>[]>([])
  useEffect(() => {
    if (props.columns) {
      setColumns(props.columns)
    }
  }, [props.columns])
  const handleResize: Function =
    (index: number) =>
    (_: React.SyntheticEvent<Element>, { size }: any) => {
      const newColumns = [...columns]
      newColumns[index] = {
        ...newColumns[index],
        width: size.width,
      }

      setColumns(newColumns)
    }

  const tablePropsWithResize = {
    ...props,
    components: {
      ...props.components,
      header: {
        cell: ResizableCell,
      },
    },
  }

  return (
    <TableAntd
      rowClassName={(record, index) => {
        if (index % 2 === 0) {
          return index.toString()
        }
        return 'row-odd'
      }}
      {...tablePropsWithResize}
      columns={columns.map((column, index: number) => ({
        ...column,
        onHeaderCell: (column: any) => ({
          width: column.width,
          onResize: handleResize(index) as React.ReactEventHandler<any>,
        }),
      }))}
      pagination={{ ...props.pagination, hideOnSinglePage: true }}
    />
  )
}

import { List } from 'antd'
import styled from 'styled-components'

export const AvatarStyle = styled.div`
  .ant-avatar {
    background-color: #ffffff;
    cursor: pointer;
    color: #3d4e44;
    margin-right: 1em;
  }
`

export const ListItemStyle = styled(List.Item)<{ hover: number }>`
  cursor: ${({ hover }) => (hover ? 'pointer' : 'default')};
  &:hover {
    background-color: ${({ hover }) => (hover ? 'rgba(0, 0, 0, 0.06)' : 'inherit')};
  }
`

import { tokenAtom } from 'Components/MicrosoftAuth/store/atom'
import { getUserInfo } from 'api/requests'
import { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userInfoAtom } from 'recoil/atom'

export const useUserInfo = () => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom)
  const token = useRecoilValue(tokenAtom)
  useEffect(() => {
    const getUserInfoRequest = async () => {
      const response = await getUserInfo()
      setUserInfo(response)
    }
    if (token === '') {
      setUserInfo({
        id: null,
        nombre: '',
        apellidos: '',
        correoElectronico: '',
        rolId: 0,
        rolDescription: '',
        permisos: [],
      })
    } else {
      getUserInfoRequest()
    }
  }, [token])

  return userInfo
}

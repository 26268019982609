const Logout = ({ onLogout, children }: { onLogout: Function; children: any }) => {
  return (
    <div data-testid="user-logout" onClick={onLogout as any}>
      {children}
    </div>
  );
};

Logout.defaultProps = {
  onLogout: () => {},
  children: "Logout",
};

export default Logout;

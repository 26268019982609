import { Collapse, Typography } from 'antd'
import OrdersAccordion from './NoticeAccordion'

const { Panel } = Collapse
const { Title } = Typography

interface ClientModel {
  id: any
  nombre: string
}
interface Props {
  clients: ClientModel[]
}

const ClientComponent = ({ clients }: Props) => {
  return (
    <div style={{ backgroundColor: '#F5F5F5' }}>
      <Title level={4} style={{ paddingLeft: '0.2em', marginTop: '1em', paddingBottom: '0em' }}>
        Clientes comerciales
      </Title>
      <Collapse defaultActiveKey="1">
        {clients.map((client) => {
          return (
            <Panel header={client.nombre} key={client.id}>
              <OrdersAccordion clientId={client.id} />
            </Panel>
          )
        })}
      </Collapse>
    </div>
  )
}

export default ClientComponent

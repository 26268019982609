/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios'

import { Modal } from 'antd'
import { ENVIRONMENT_CONFIG } from 'Components/MicrosoftAuth/environmentOptions'

export interface HeaderRequestModel {
  Accept?: string
  'Content-Type': string
  'Cache-Control': string
  Authorization?: string
}

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const messageError = error?.response?.data?.error?.message || error.message
    const modal = Modal.error({})
    if (error.response?.status === 401) {
      modal.update({
        title: 'Acceso denegado',
        content: 'No tienes los permisos necesarios para realizar esta acción',
      })
      // window.location.href = '/logout'
    } else {
      if (error.response?.status === 400) {
        modal.update({
          title: 'Error',
          content: error?.response?.data,
        })
      } else {
        console.log(error)
        modal.update({
          title: 'Error',
          content: messageError,
        })
      }
    }

    return Promise.reject(error)
  }
)

const getHeaders = (options: any) => {
  const token = localStorage.getItem(ENVIRONMENT_CONFIG.TOKEN_NAME) || '""'
  const params = options.params ? options.params : undefined
  let headers = {
    'Content-Type': options['Content-Type'] ? options['Content-Type'] : 'application/json',
  } as HeaderRequestModel
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  return {
    params,
    headers: { ...headers },
    responseType: options?.responseType,
    authRequired: options?.authRequired ? options.authRequired : true,
  }
}

export const makeGetRequest = (url: string, options: {}) => {
  return axios.get(url, getHeaders(options)).then((response) => response.data)
  //.catch((error: any) => message.error(error.message, 10));
}

export const makePostRequest = (url: string, data: any, options: any = {}) => {
  return axios
    .post(url, data, getHeaders(options))
    .then((response) => response.data)
    .catch((error) => {
      console.error(error)
      throw error
    })
}

export const makePutRequest = (url: string, data: any, options: any = {}) => {
  return axios
    .put(url, data, getHeaders(options))
    .then((response) => response.data)
    .catch((error) => {
      console.error(error)
      throw error
    })
}

export const makeDeleteRequest = (url: string, data: any, options: any = {}) => {
  return axios
    .delete(url, { ...getHeaders(options), data: data })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error)
      throw error
    })
}

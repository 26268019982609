import { useNavigate } from 'react-router-dom'
import { useTokenStore } from './standard/useToken'
import { useMsal } from '@azure/msal-react'

export const useLogout = () => {
  const navigate = useNavigate()
  const { setTokenValues } = useTokenStore()
  const { instance } = useMsal()

  return () => {
    instance.logout()
    setTokenValues('')
    navigate('/login')
  }
}

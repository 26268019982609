import { Table } from 'Components/Table'
import { dataSourceWithId, generateTableColumns, ROW_ID } from 'Components/Table/GenerateColumns'
import { OrderColumnsModel, PedidosVEsModel } from 'domain/Orders'
import { formatTableColumns } from './Helper'
import { STYLES } from './syles'

const VesTable = ({ orders, columns }: { orders: PedidosVEsModel[]; columns: OrderColumnsModel }) => (
  <Table
    columns={formatTableColumns(generateTableColumns(columns.vEs))}
    expandable={{
      expandedRowRender: (record) => (
        <Table
          columns={generateTableColumns(columns.lineas)}
          dataSource={record.lineas}
          scroll={{ x: '40vw', y: '65vh' }}
        />
      ),
      rowExpandable: (record) => record.id !== 'Not Expandable',
    }}
    rowKey={ROW_ID}
    style={{ width: '80vw', ...STYLES.sectionTablePaddings }}
    dataSource={dataSourceWithId(orders)}
    scroll={{ x: '50vw', y: '65vh' }}
  />
)

export default VesTable

import { Pagination } from 'antd'
import { ManageStatus } from 'Components/ManageStatus/ManageStatus'
import { DEFAULT_PAGINATION_CONFIG } from 'Components/Table/Constants'
import ClientComponent from './ClientAccordion'
import './index.css'
interface Props {
  data: any
  status: any
  onChangePagination: any
  currentPage: number
}
const HistoricalList = ({ data, onChangePagination, status, currentPage }: Props) => {
  return (
    <div style={{ minHeight: '40vh', backgroundColor: 'white' }}>
      <div style={{ minHeight: '40vh' }}>
        <ManageStatus status={status} fullScreen>
          <div>
            <ClientComponent clients={data?.result?.datos} />
            <Pagination
              style={{ right: 0, position: 'absolute', paddingTop: '0.5em' }}
              total={data?.result?.total}
              onChange={onChangePagination}
              current={currentPage}
              {...DEFAULT_PAGINATION_CONFIG}
            />
          </div>
        </ManageStatus>
      </div>
    </div>
  )
}

export default HistoricalList
